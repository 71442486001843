@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Bold.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Light.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Medium.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Regular.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Medium.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Regular.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Light.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Regular.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Bold.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Medium.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Light.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('./assets/font/HyundaiSansHeadOffice-Bold.woff2') format('woff2'),
    url('./assets/font/HyundaiSansHeadOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Italic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-BoldItalic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Bold.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-BoldItalic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Bold.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Italic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Medium.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-BoldItalic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Bold.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Italic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Regular.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-MediumItalic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-MediumItalic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Medium.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Medium.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Regular.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-MediumItalic.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('./assets/font/HyundaiSansTextOffice-Regular.woff2') format('woff2'),
    url('./assets/font/HyundaiSansTextOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
